
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import {
  LinkIcon, PlusIcon, QuestionMarkCircleIcon, ExclamationCircleIcon,
} from '@heroicons/vue/solid';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    ExclamationCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
    editingItem: { name: String, id: Number },
  },
  emits: [
    'closed',
  ],
})
export default class AddEditYearSlideOver extends Vue {
  open!: boolean;

  editingItem!: { name: string };

  yearError: string | null = null;

  close(refresh = false): void {
    this.yearError = null;
    this.$emit('closed', refresh);
  }

  get yearErrorClass() {
    return this.yearError ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : '';
  }

  create(): void {
    this.axios.put(`${process.env.VUE_APP_APIURL}/years`, this.editingItem)
      .then(() => this.close(true))
      .catch((err) => {
        if (err.request) {
          this.yearError = err.request.response;
        }
      });
  }
}
