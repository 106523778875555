
import { Options, Vue } from 'vue-class-component';
import {
  Disclosure, DisclosureButton, DisclosurePanel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import AddEditYearSlideOver from '@/components/SlideOvers/AddEditYearSlideOver.vue';

@Options({
  components: {
    AddEditYearSlideOver,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
})
export default class Results extends Vue {
  addEditOpen = false;

  years = [
    // More items...
  ]

  noviceYears = [
    // More items...
  ]

  statusStyles = {
    published: 'bg-green-100 text-green-800',
    draft: 'bg-yellow-100 text-yellow-800',
    empty: 'bg-gray-100 text-gray-800',
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.refreshData();
    }
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years/totals`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.years = res.data);
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years/totals/novice`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.noviceYears = res.data);
  }
}
