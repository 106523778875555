<template>
  <div class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
    <dt>
      <div class="absolute bg-indigo-500 rounded-md p-3 text-white">
        {{ positionText }}
      </div>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        {{ person?.id ? `${person.name} ${person.surname}` : 'Not Set' }}
      </p>
      <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6" @click="placeCompetitor">
        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Change</a
          >
        </div>
      </div>
    </dd>
    <place-competitor-modal :open="modalOpen" @closed="closeModal" v-bind="$props" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';
import PlaceCompetitorModal from '@/components/modals/PlaceCompetitorModal.vue';

@Options({
  components: { PlaceCompetitorModal },
  props: {
    position: Number,
    person: Competitor,
    slug: String,
    year: String,
    categoryId: Number,
  },
  emits: ['update'],
})
export default class Placing extends Vue {
  position!: 1|2|3

  modalOpen = false;

  person!: Competitor;

  get positionText(): string {
    switch (this.position) {
      case 1: return '1st';
      case 2: return '2nd';
      case 3: return '3rd';
      default: return '';
    }
  }

  placeCompetitor() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
    this.$emit('update');
  }
}
</script>
