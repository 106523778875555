
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditShowSlideOver from '@/components/SlideOvers/AddEditShowSlideOver.vue';
import IShow from '@/models/interfaces/IShow';
import EditYearModal from '@/components/modals/EditYearModal.vue';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    EditYearModal,
    AddEditShowSlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Year extends Vue {
  addEditOpen = false;

  editYearOpen = false;

  editingItem: { name: string, year: string, location: string, date: Date } | null = null;

  calendar: { name: string, number: number, shows: { name: string, year: string, location: string, date: Date }[] }[] | null = null;

  yearObject: { id: string, name: string, published: boolean, shows: IShow[] } | null = null;

  shows: IShow[] | null = null;

  areYouSureDeleteOpen = false;

  created() {
    this.loadShows();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  // eslint-disable-next-line class-methods-use-this
  addShowClicked(): void {
    this.editingItem = {
      name: '', year: this.year, location: '', date: new Date(parseInt(this.year, 10), 0, 1),
    };
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  editShowClicked(): void {
    this.editYearOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  closeEditYear(refresh: boolean): void {
    this.editYearOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadShows(): void {
    console.log('Loading shows...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/years/${this.year}`)
      .then((res) => {
        this.yearObject = res.data;
        this.shows = res.data.shows;
      });
  }

  deleteYear(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('deleting');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/years/${this.yearObject?.id}`)
      .then((res) => {
        this.$router.push({ name: 'Years' });
      });
  }
}
