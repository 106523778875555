import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import any other Firebase libs like firestore, etc
import config from './config';
import store from '../store'; // import the Vuex store

const app = initializeApp(config);
const analytics = getAnalytics(app);

export const auth = getAuth();
// export other Firebase bits like db, etc

const onAuthStateChangedPromise = new Promise((resolve, reject) => {
  auth.onAuthStateChanged((user) => {
    console.log(`Auth state changed logged ${user ? 'in' : 'out'}`);
    store.dispatch(user !== null ? 'login' : 'logout', user);
    resolve(user);
  }, (err) => {
    reject(err);
  });
});

export const onAuthStateInit = () => onAuthStateChangedPromise;
