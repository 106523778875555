<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <img class="hidden h-16 w-16 rounded-full sm:block"
                   src="https://img.icons8.com/ios-filled/2x/user.png"
                   alt=""/>
              <div>
                <div class="flex items-center">
                  <img class="h-16 w-16 rounded-full sm:hidden"
                       src="https://img.icons8.com/ios-filled/2x/user.png"
                       alt=""/>
                  <h1
                    class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {{ salutation }}, {{ userName }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-lg leading-6 font-medium text-gray-900">Years</h2>
        <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <!-- Card -->
          <div v-for="card in cards" :key="card.name"
               class="bg-white overflow-hidden shadow rounded-lg">
            <div class="p-5">
              <div class="flex items-center">
                <div class="w-0 flex-1">
                  <dl>
                    <dd>
                      <div class="text-xl font-medium text-gray-900">
                        {{ card.name }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-5 py-3">
              <div class="text-sm">
                <router-link :to="{ name: 'Year', params: { year: card.name }}" tag="a"
                             class="font-medium text-cyan-700 hover:text-cyan-900">
                  View shows
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Home extends Vue {
  navigation = [
    {
      name: 'Home', href: '#', icon: HomeIcon, current: true,
    },
    {
      name: 'History', href: '#', icon: ClockIcon, current: false,
    },
    {
      name: 'Balances', href: '#', icon: ScaleIcon, current: false,
    },
    {
      name: 'Cards', href: '#', icon: CreditCardIcon, current: false,
    },
    {
      name: 'Recipients', href: '#', icon: UserGroupIcon, current: false,
    },
    {
      name: 'Reports', href: '#', icon: DocumentReportIcon, current: false,
    },
  ];

  secondaryNavigation = [
    { name: 'Settings', href: '#', icon: CogIcon },
    { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
    { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
  ]

  cards = [
  ]

  transactions = [
    {
      id: 1,
      name: 'Payment to Molly Sanders',
      href: '#',
      amount: '$20,000',
      currency: 'USD',
      status: 'success',
      date: 'July 11, 2020',
      datetime: '2020-07-11',
    },
    // More transactions...
  ]

  statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.cards = res.data.map((d: { id: number, name: string }) => ({
        name: d.name,
        href: '#',
      })));
  }

  // eslint-disable-next-line class-methods-use-this
  get salutation(): string {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return 'Good morning';
    } if (curHr < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }

  sidebarOpen = false;

  get userName() {
    const { user } = this.$store.getters;
    console.log(user);
    return user ? user.displayName : 'User';
  }
}
</script>
