import { createStore } from 'vuex';

export default createStore({
  state: {
    editingCategory: {
      name: '',
      description: '',
      novice: false,
    },
    editingCategoryTemplate: {
      name: '',
      description: '',
      novice: false,
    },
    editingCompetitor: {
      name: '',
      surname: '',
      notes: '',
      id: null,
      novice: false,
      active: true,
      noviceYear: null,
    },
    competitors: [],
    user: null,
  },
  mutations: {
    editCategory(state, payload) {
      state.editingCategory = payload;
    },
    addCategory(state, payload) {
      state.editingCategory = payload;
    },
    setEditCategoryName(state, payload) {
      state.editingCategory.name = payload;
    },
    setEditCategoryDescription(state, payload) {
      state.editingCategory.description = payload;
    },
    setEditCategoryNovice(state, payload) {
      state.editingCategory.novice = payload;
    },
    editCategoryTemplate(state, payload) {
      state.editingCategoryTemplate = payload;
    },
    addCategoryTemplate(state, payload) {
      state.editingCategoryTemplate = payload;
    },
    setEditCategoryTemplateName(state, payload) {
      state.editingCategoryTemplate.name = payload;
    },
    setEditCategoryTemplateDescription(state, payload) {
      state.editingCategoryTemplate.description = payload;
    },
    setEditCategoryTemplateNovice(state, payload) {
      state.editingCategoryTemplate.novice = payload;
    },
    editCompetitor(state, payload) {
      state.editingCompetitor = payload;
    },
    addCompetitor(state, payload) {
      state.editingCompetitor = payload;
    },
    setEditCompetitorName(state, payload) {
      state.editingCompetitor.name = payload;
    },
    setEditCompetitorSurname(state, payload) {
      state.editingCompetitor.surname = payload;
    },
    setEditCompetitorNotes(state, payload) {
      state.editingCompetitor.notes = payload;
    },
    setEditCompetitorNovice(state, payload) {
      state.editingCompetitor.novice = payload;
    },
    setEditCompetitorNoviceYear(state, payload) {
      state.editingCompetitor.noviceYear = payload;
    },
    setEditCompetitorActive(state, payload) {
      state.editingCompetitor.active = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCompetitors(state, payload) {
      state.competitors = payload;
    },
  },
  actions: {
    addCategory(context) {
      context.commit('addCategory', { name: '', description: '', id: undefined });
    },
    editCategory(context, payload) {
      context.commit('editCategory', payload);
    },
    setEditCategoryName(context, payload) {
      context.commit('setEditCategoryName', payload);
    },
    setEditCategoryDescription(context, payload) {
      context.commit('setEditCategoryDescription', payload);
    },
    setEditCategoryNovice(context, payload) {
      context.commit('setEditCategoryNovice', payload);
    },
    addCategoryTemplate(context) {
      context.commit('addCategoryTemplate', { name: '', description: '', id: undefined });
    },
    editCategoryTemplate(context, payload) {
      context.commit('editCategoryTemplate', payload);
    },
    setEditCategoryTemplateName(context, payload) {
      context.commit('setEditCategoryTemplateName', payload);
    },
    setEditCategoryTemplateDescription(context, payload) {
      context.commit('setEditCategoryTemplateDescription', payload);
    },
    setEditCategoryTemplateNovice(context, payload) {
      context.commit('setEditCategoryTemplateNovice', payload);
    },
    addCompetitor(context) {
      context.commit('addCompetitor', {
        name: '',
        surname: '',
        notes: '',
        novice: false,
        active: true,
        id: null,
      });
    },
    editCompetitor(context, payload) {
      context.commit('editCompetitor', payload);
    },
    setEditCompetitorName(context, payload) {
      context.commit('setEditCompetitorName', payload);
    },
    setEditCompetitorSurname(context, payload) {
      context.commit('setEditCompetitorSurname', payload);
    },
    setEditCompetitorNotes(context, payload) {
      context.commit('setEditCompetitorNotes', payload);
    },
    setEditCompetitorNovice(context, payload) {
      context.commit('setEditCompetitorNovice', payload);
    },
    setEditCompetitorNoviceYear(context, payload) {
      context.commit('setEditCompetitorNoviceYear', payload);
    },
    setEditCompetitorActive(context, payload) {
      context.commit('setEditCompetitorActive', payload);
    },
    setCompetitors(context, payload) {
      context.commit('setCompetitors', payload);
    },
    login(context, payload) {
      context.commit('setUser', payload);
    },
    logout(context) {
      context.commit('setUser', null);
    },
  },
  modules: {
  },
  getters: {
    isAuthenticated: (state) => typeof state.user === 'object' && state.user !== null,
    user: (state) => state.user,
    competitors: (state) => state.competitors,
  },
});
