<template>
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Results
        </h2>
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" v-for="year in years" :key="year.name" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-gray-900">
                  {{ year.name }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <DisclosurePanel as="dd" class="mt-2 pr-12">
                <div class="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li v-for="(competitor, idx) in year.competitors" :key="idx">
                      <a href="#" class="block hover:bg-gray-50">
                        <div class="flex items-center px-4 py-4 sm:px-6">
                          <div class="min-w-0 flex-1 flex items-center">
                            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <p class="text-sm font-medium text-indigo-600 truncate">{{ `${competitor.name} ${competitor.surname}` }}</p>
                                <p class="mt-2 flex items-center text-sm text-gray-500 block md:hidden">
                                  <span class="truncate">{{ competitor.score }} pts</span>
                                </p>
                              </div>
                              <div class="hidden md:block">
                                <div>
                                  <p class="text-sm text-gray-900">
                                    {{ competitor.score }} pts
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <h5 class="my-5">Novice</h5>
                <div class="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li v-for="(competitor, idx) in noviceYears.find(y => y.name == year.name).competitors" :key="idx">
                      <a href="#" class="block hover:bg-gray-50">
                        <div class="flex items-center px-4 py-4 sm:px-6">
                          <div class="min-w-0 flex-1 flex items-center">
                            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <p class="text-sm font-medium text-indigo-600 truncate">{{ `${competitor.name} ${competitor.surname}` }}</p>
                                <p class="mt-2 flex items-center text-sm text-gray-500 block md:hidden">
                                  <span class="truncate">{{ competitor.score }} pts</span>
                                </p>
                              </div>
                              <div class="hidden md:block">
                                <div>
                                  <p class="text-sm text-gray-900">
                                    {{ competitor.score }} pts
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </DisclosurePanel>
            </transition>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Disclosure, DisclosureButton, DisclosurePanel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import AddEditYearSlideOver from '@/components/SlideOvers/AddEditYearSlideOver.vue';

@Options({
  components: {
    AddEditYearSlideOver,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
})
export default class Results extends Vue {
  addEditOpen = false;

  years = [
    // More items...
  ]

  noviceYears = [
    // More items...
  ]

  statusStyles = {
    published: 'bg-green-100 text-green-800',
    draft: 'bg-yellow-100 text-yellow-800',
    empty: 'bg-gray-100 text-gray-800',
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.refreshData();
    }
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years/totals`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.years = res.data);
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years/totals/novice`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.noviceYears = res.data);
  }
}
</script>
