<template>
  <ul role="list" class="divide-y divide-gray-200 flex flex-col">
    <li v-for="(person, idx) in competitors" :key="idx" class="py-4 flex justify-between">
      <div class="ml-3 inline-flex">
        <p class="text-md font-medium text-gray-900 my-1 py-0.5 px-3">{{person.name}} {{person.surname}}</p>
        <span v-if="person.novice" class="inline-flex items-center px-3 py-0.5 my-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
          Novice{{ person.noviceYear ? ` ${person.noviceYear}` : null}}
        </span>
      </div>
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
        @click="select(person)"
      >
        Select
      </button>
    </li>
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';

@Options({
  props: {
    competitors: Array(Competitor),
  },
  emits: ['select'],
})
export default class PersonSelectList extends Vue {
  competitors!: Competitor[]

  select(person: Competitor) {
    this.$emit('select', person);
  }
}
</script>
