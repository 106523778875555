
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String, year: String, location: String, date: Date,
    },
    slug: String,
    year: String,
  },
  emits: [
    'closed',
  ],
})
export default class EditShowModal extends Vue {
  editingItem!: { name: string, year: string, location: string, date: Date, judge: string };

  slug!: string;

  name = '';

  year!: string;

  location = '';

  judge = '';

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  created() {
    console.log(this.editingItem);
    this.name = this.editingItem.name;
    this.location = this.editingItem.location;
    this.judge = this.editingItem.judge;
  }

  close() {
    this.$emit('closed');
  }

  update() {
    // Send request to update show
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`,
      {
        name: this.name,
        year: this.year,
        location: this.location,
        date: this.editingItem.date,
        slug: this.slug,
        judge: this.judge,
      },
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }

  get selectedDate() {
    return new Date(this.editingItem.date).getDate();
  }

  set selectedDate(val: number) {
    this.editingItem.date = new Date(this.selectedYear, this.selectedMonth!.id - 1, val);
  }

  get selectedMonth(): { id: number, name: string } {
    return this.months[new Date(this.editingItem.date).getMonth()];
  }

  set selectedMonth(val: { id: number, name: string }) {
    this.editingItem.date = new Date(this.selectedYear, val!.id - 1, this.selectedDate);
  }

  get selectedYear(): number {
    return new Date(this.editingItem.date).getFullYear();
  }

  set selectedYear(val: number) {
    this.editingItem.date = new Date(val, this.selectedMonth!.id - 1, this.selectedDate);
  }
}
