
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import MergeCompetitorModal from '@/components/modals/MergeCompetitorModal.vue';

@Options({
  components: {
    MergeCompetitorModal,
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
  },
  emits: [
    'closed',
  ],
})
export default class AddEditCompetitorSlideOver extends Vue {
  open!: boolean;

  modalOpen = false;

  closeModal(): void {
    this.modalOpen = false;
  }

  get isEditing(): boolean {
    console.log(this.$store.state.editingCompetitor);
    return !!this.$store.state.editingCompetitor.id;
  }

  get editingCompetitorId(): string {
    return this.$store.state.editingCompetitor.id;
  }

  get editingItemName(): string {
    return this.$store.state.editingCompetitor.name;
  }

  set editingItemName(val: string) {
    this.$store.dispatch('setEditCompetitorName', val);
  }

  get editingItemSurname(): string {
    return this.$store.state.editingCompetitor.surname;
  }

  set editingItemSurname(val: string) {
    this.$store.dispatch('setEditCompetitorSurname', val);
  }

  get editingItemNovice(): boolean {
    return this.$store.state.editingCompetitor.novice;
  }

  set editingItemNovice(val: boolean) {
    this.$store.dispatch('setEditCompetitorNovice', val);
  }

  get editingItemNoviceYear(): boolean {
    return this.$store.state.editingCompetitor.noviceYear;
  }

  set editingItemNoviceYear(val: boolean) {
    this.$store.dispatch('setEditCompetitorNoviceYear', val);
  }

  close(refresh = false): void {
    this.$emit('closed', refresh);
  }

  create(): void {
    if (!this.isEditing) {
      this.axios.put(`${process.env.VUE_APP_APIURL}/competitors`, this.$store.state.editingCompetitor)
        .then(() => {
          this.close(true);
        });
    } else {
      this.axios.post(`${process.env.VUE_APP_APIURL}/competitors`, this.$store.state.editingCompetitor)
        .then(() => this.close(true));
    }
  }

  merge(): void {
    if (this.isEditing) {
      console.log('Merging');
    }
  }

  deactivate(): void {
    this.axios.delete(`${process.env.VUE_APP_APIURL}/competitors/${this.$store.state.editingCompetitor.id}`)
      .then(() => this.close(true));
  }
}
