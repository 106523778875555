
import { Options, Vue } from 'vue-class-component';
import CategoryTemplate from '@/models/CategoryTemplate';

@Options({
  props: {
    categories: Array(CategoryTemplate),
    templateId: String,
  },
  emits: ['editing', 'update'],
})
export default class TemplateCategoryList extends Vue {
  categories!: CategoryTemplate[];

  templateId!: string;

  edit(category: CategoryTemplate) {
    this.$emit('editing', category);
  }

  update() {
    this.$emit('update');
  }
}
