
import { Options, Vue } from 'vue-class-component';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt1Icon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';
import AddEditYearSlideOver from '@/components/SlideOvers/AddEditYearSlideOver.vue';

@Options({
  components: {
    AddEditYearSlideOver,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Years extends Vue {
  addEditOpen = false;

  editingItem: { name: string, id?: number | undefined } | undefined;

  cards = [
    // More items...
  ]

  statusStyles = {
    published: 'bg-green-100 text-green-800',
    draft: 'bg-yellow-100 text-yellow-800',
    empty: 'bg-gray-100 text-gray-800',
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.refreshData();
    }
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.cards = res.data.map((d: { id: number, name: string }) => ({
        name: d.name,
        href: '#',
      })));
  }

  addNewClicked(): void {
    const nextYear = new Date().getFullYear().toString();
    this.editingItem = { name: nextYear };
    this.addEditOpen = true;
  }
}
