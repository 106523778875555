
import { Options, Vue } from 'vue-class-component';
import Placing from '@/components/Placing.vue';
import BestInShow from '@/components/BestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { BestInShow, Placing },
  props: {
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
    slug: String,
    year: String,
  },
  emits: ['editing', 'update'],
})
export default class ShowJudgeBestInShow extends Vue {
  bestInShow!: Competitor;

  noviceBestInShow!: Competitor;

  update() {
    this.$emit('update');
  }
}
