
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import ITemplate from '@/models/interfaces/ITemplate';
import TemplateList from '@/components/TemplateList.vue';
import AddEditTemplateSlideOver from '@/components/SlideOvers/AddEditTemplateSlideOver.vue';

@Options({
  components: {
    AddEditTemplateSlideOver,
    TemplateList,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Templates extends Vue {
  addEditOpen = false;

  editingItem: { name: string } | null = null;

  templates: ITemplate[] | null = null;

  created() {
    this.loadTemplates();
  }

  // eslint-disable-next-line class-methods-use-this
  addTemplateClicked(): void {
    this.editingItem = {
      name: '',
    };
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadTemplates(): void {
    console.log('Loading templates...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/templates`)
      .then((res) => {
        this.templates = res.data;
      });
  }
}
