
import { Options, Vue } from 'vue-class-component';
import ICompetitor from '@/models/interfaces/ICompetitor';
import { UsersIcon } from '@heroicons/vue/solid';

@Options({
  props: {
    competitors: Array,
  },
  emits: ['editing'],
  components: {
    UsersIcon,
  },
})
export default class CompetitorList extends Vue {
  // eslint-disable-next-line class-methods-use-this
  competitors!: ICompetitor[];

  filterValue = '';

  get filteredCompetitors(): ICompetitor[] {
    return this.filterValue.trim().length < 1 ? this.competitors.sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1))
      : this.competitors?.filter((c, i, a) => (`${c.name.toLowerCase()} ${c.surname.toLowerCase()}`).indexOf(this.filterValue.toLowerCase()) >= 0).sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1));
  }

  edit(competitor: ICompetitor) {
    this.$emit('editing', competitor);
  }
}
