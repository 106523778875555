
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import CompetitorList from '@/components/CompetitorList.vue';
import AddEditCompetitorSlideOver from '@/components/SlideOvers/AddEditCompetitorSlideOver.vue';
import ICompetitor from '@/models/interfaces/ICompetitor';

@Options({
  components: {
    AddEditCompetitorSlideOver,
    CompetitorList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Competitors extends Vue {
  addEditOpen = false;

  competitors: ICompetitor[] | null = null;

  created() {
    this.loadCompetitors();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  addCompetitorClicked(): void {
    this.$store.dispatch('addCompetitor');
    this.addEditOpen = true;
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    this.loadCompetitors();
  }

  loadCompetitors(): void {
    this.axios.get(`${process.env.VUE_APP_APIURL}/competitors`)
      .then((res) => {
        this.competitors = res.data;
      });
  }

  editItem(editCompetitor: ICompetitor) {
    this.$store.dispatch('editCompetitor', editCompetitor);
    this.addEditOpen = true;
  }
}
