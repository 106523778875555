import ICategoryTemplate from '@/models/interfaces/ICategoryTemplate';

export default class CategoryTemplate implements ICategoryTemplate {
  name: string;

  description: string;

  novice = false;

  constructor(name: string, description: string, novice: boolean) {
    this.name = name;
    this.description = description;
    this.novice = novice;
  }
}
