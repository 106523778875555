<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Show Templates
              </h2>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="addTemplateClicked">
              Add Template
            </button>
          </div>
        </div>
      </div>
    </div>
    <template-list v-if="templates" :templates="templates"/>
    <add-edit-template-slide-over :open="addEditOpen" :editingItem="editingItem" @closed="closeSlideover" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import ITemplate from '@/models/interfaces/ITemplate';
import TemplateList from '@/components/TemplateList.vue';
import AddEditTemplateSlideOver from '@/components/SlideOvers/AddEditTemplateSlideOver.vue';

@Options({
  components: {
    AddEditTemplateSlideOver,
    TemplateList,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Templates extends Vue {
  addEditOpen = false;

  editingItem: { name: string } | null = null;

  templates: ITemplate[] | null = null;

  created() {
    this.loadTemplates();
  }

  // eslint-disable-next-line class-methods-use-this
  addTemplateClicked(): void {
    this.editingItem = {
      name: '',
    };
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadTemplates(): void {
    console.log('Loading templates...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/templates`)
      .then((res) => {
        this.templates = res.data;
      });
  }
}
</script>
