
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';
import PersonSelectList from '@/components/PersonSelectList.vue';
import Competitor from '@/models/Competitor';
import AddEditCompetitorSlideOver from '@/components/SlideOvers/AddEditCompetitorSlideOver.vue';
import ICompetitor from '@/models/interfaces/ICompetitor';

@Options({
  components: {
    AddEditCompetitorSlideOver,
    PersonSelectList,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String, year: String, location: String, date: Date,
    },
    slug: String,
    year: String,
    categoryId: Number,
    position: Number,
    bestInShow: { type: Boolean, default: false },
    novice: { type: Boolean, default: false },
  },
  emits: [
    'closed',
  ],
})
export default class PlaceCompetitorModal extends Vue {
  addEditOpen = false;

  bestInShow = false;

  novice = false;

  slug!: string;

  year!: string;

  categoryId!: number;

  position!: 1|2|3;

  filterValue = '';

  get filteredCompetitors() {
    return this.filterValue.trim().length < 1 ? this.competitors.sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1))
      : this.competitors?.filter((c, i, a) => (`${c.name.toLowerCase()} ${c.surname.toLowerCase()}`).indexOf(this.filterValue.toLowerCase()) >= 0).sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1));
  }

  mounted() {
    this.loadCompetitors();
  }

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  get competitors(): ICompetitor[] {
    return this.$store.getters.competitors;
  }

  close() {
    this.$emit('closed');
  }

  closeSlideover() {
    this.addEditOpen = false;
    this.loadCompetitors();
  }

  loadCompetitors(): void {
    this.axios.get(`${process.env.VUE_APP_APIURL}/competitors`)
      .then((res) => {
        this.$store.dispatch('setCompetitors', res.data);
      });
  }

  update(person: Competitor) {
    // Send request to update placing
    console.log(person);
    if (this.bestInShow) {
      if (this.novice) {
        this.updateNoviceBestInShow(person);
      } else {
        this.updateBestInShow(person);
      }
    } else {
      this.updatePlacing(person);
    }
  }

  private updatePlacing(person: Competitor) {
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/categories/${this.categoryId}/${this.position}`,
      person,
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }

  private updateBestInShow(person: Competitor) {
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/bestInShow`,
      person,
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }

  private updateNoviceBestInShow(person: Competitor) {
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/noviceBestInShow`,
      person,
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }

  addNewPerson() {
    this.$store.dispatch('addCompetitor');
    this.addEditOpen = true;
  }
}
