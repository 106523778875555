<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Name
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input type="text" name="name" id="name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="name" />
                  </div>

                  <div>
                    <label for="location" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Location
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input type="text" name="location" id="location" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="location" />
                  </div>

                  <div>
                    <label for="month" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Month
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <select-menu :items="months" v-model="selectedMonth" id="month"/>
                    <!--                        <input type="text" name="year" id="year" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItem.name" />-->
                  </div>
                  <div>
                    <label for="date" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Date
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input type="number" name="date" id="date" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="selectedDate" />
                  </div>
                  <div>
                    <label for="year" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Year
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input type="number" name="year" id="year" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="selectedYear" />
                  </div>

                  <div>
                    <label for="judge" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Judge
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input type="text" name="judge" id="judge" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="judge" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="update">
                Save
              </button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="close" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String, year: String, location: String, date: Date,
    },
    slug: String,
    year: String,
  },
  emits: [
    'closed',
  ],
})
export default class EditShowModal extends Vue {
  editingItem!: { name: string, year: string, location: string, date: Date, judge: string };

  slug!: string;

  name = '';

  year!: string;

  location = '';

  judge = '';

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  created() {
    console.log(this.editingItem);
    this.name = this.editingItem.name;
    this.location = this.editingItem.location;
    this.judge = this.editingItem.judge;
  }

  close() {
    this.$emit('closed');
  }

  update() {
    // Send request to update show
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`,
      {
        name: this.name,
        year: this.year,
        location: this.location,
        date: this.editingItem.date,
        slug: this.slug,
        judge: this.judge,
      },
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }

  get selectedDate() {
    return new Date(this.editingItem.date).getDate();
  }

  set selectedDate(val: number) {
    this.editingItem.date = new Date(this.selectedYear, this.selectedMonth!.id - 1, val);
  }

  get selectedMonth(): { id: number, name: string } {
    return this.months[new Date(this.editingItem.date).getMonth()];
  }

  set selectedMonth(val: { id: number, name: string }) {
    this.editingItem.date = new Date(this.selectedYear, val!.id - 1, this.selectedDate);
  }

  get selectedYear(): number {
    return new Date(this.editingItem.date).getFullYear();
  }

  set selectedYear(val: number) {
    this.editingItem.date = new Date(val, this.selectedMonth!.id - 1, this.selectedDate);
  }
}
</script>
