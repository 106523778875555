<template>
  <div v-if="currentTemplate">
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {{ currentTemplate.name }}
              </h2>
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <LocationMarkerIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {{ currentTemplate.location }}
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              @click="deleteTemplate"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Delete
            </button>
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="editDetails"
            >
              <PencilIcon class="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              Edit
            </button>
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="addCategoryClicked">
              Add Template Category
            </button>
          </div>
        </div>
      </div>
    </div>
    <template-category-list :categories="currentTemplate.categories" @editing="editItem" @update="loadTemplates" :template-id="currentTemplate.id"/>

    <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4" v-if="currentTemplate.categories.length > 0">
      <button type="button"
              class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="addCategoryClicked">
        Add Template Category
      </button>
    </div>
    <add-edit-category-template-slide-over :open="addEditOpen" :editingItem="editingItem" :template-id="templateId" @closed="closeSlideover" />
    <edit-template-modal :open="editModalOpen" :editing-item="currentTemplate" :template-id="templateId" @closed="closeEditModal"/>
    <are-you-sure-modal @confirm="confirmDelete" @closed="areYouSureDeleteOpen = false" :open="areYouSureDeleteOpen" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
  PencilIcon,
  CalendarIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditCategorySlideOver from '@/components/SlideOvers/AddEditCategorySlideOver.vue';
import ITemplate from '@/models/interfaces/ITemplate';
import ShowCategoryList from '@/components/ShowCategoryList.vue';
import EditShowModal from '@/components/modals/EditShowModal.vue';
import ICategoryTemplate from '@/models/interfaces/ICategoryTemplate';
import EditTemplateModal from '@/components/modals/EditTemplateModal.vue';
import AddEditCategoryTemplateSlideOver
  from '@/components/SlideOvers/AddEditCategoryTemplateSlideOver.vue';
import TemplateCategoryList from '@/components/TemplateCategoryList.vue';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    TemplateCategoryList,
    AddEditCategoryTemplateSlideOver,
    EditTemplateModal,
    EditShowModal,
    ShowCategoryList,
    AddEditCategorySlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    LocationMarkerIcon,
    PencilIcon,
    CalendarIcon,
  },
})
export default class Template extends Vue {
  addEditOpen = false;

  editModalOpen = false;

  editingItem: ICategoryTemplate | null = null;

  currentTemplate: ITemplate | null = null;

  areYouSureDeleteOpen = false;

  created(): void {
    this.loadTemplates();
  }

  get templateId(): string {
    return Array.isArray(this.$route.params.templateId) ? this.$route.params.templateId[0] : this.$route.params.templateId;
  }

  // eslint-disable-next-line class-methods-use-this
  addCategoryClicked(): void {
    this.$store.dispatch('addCategoryTemplate');
    this.addEditOpen = true;
  }

  editDetails(): void {
    this.editModalOpen = true;
  }

  editItem(editCategory: ICategoryTemplate): void {
    this.$store.dispatch('editCategoryTemplate', editCategory);
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  deleteTemplate(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('deleting');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/templates/${this.templateId}`)
      .then(() => {
        this.areYouSureDeleteOpen = false;
        this.$router.push({ name: 'Templates' });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  closeEditModal(refresh: boolean): void {
    this.editModalOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadTemplates(): void {
    console.log('Loading template...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/templates/${this.templateId}`)
      .then((res) => {
        console.log(res);
        this.currentTemplate = res.data;
      });
  }
}
</script>
