import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { onAuthStateInit } from '@/firebase';
import store from '@/store';
import Login from '@/views/Login.vue';
import Main from '@/Layouts/Main.vue';
import Year from '@/views/Year.vue';
import Years from '@/views/Years.vue';
import Show from '@/views/Show.vue';
import Home from '@/views/Home.vue';
import Competitors from '@/views/Competitors.vue';
import Results from '@/views/Results.vue';
import Settings from '@/views/Settings.vue';
import Templates from '@/views/Templates.vue';
import Template from '@/views/Template.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: '/year/:year',
        name: 'Year',
        component: Year,
      },
      {
        path: '/year/:year/show/:slug',
        name: 'Show',
        component: Show,
      },
      {
        path: '/years',
        name: 'Years',
        component: Years,
      },
      {
        path: '/results',
        name: 'Results',
        component: Results,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: '/competitors',
        name: 'Competitors',
        component: Competitors,
      },
      {
        path: '/templates',
        name: 'Templates',
        component: Templates,
      },
      {
        path: '/templates/:templateId',
        name: 'Template',
        component: Template,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await onAuthStateInit(); // wait for auth system to initialise
  if (to.matched.every((route) => route.meta.public) || store.getters.isAuthenticated) {
    next();
  } else {
    next({ name: 'Login' }); // redirect to sign-in page
  }
});

export default router;
