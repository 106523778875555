
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import PersonSelectList from '@/components/PersonSelectList.vue';
import Competitor from '@/models/Competitor';
import ICompetitor from '@/models/interfaces/ICompetitor';

@Options({
  components: {
    PersonSelectList,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: { type: Boolean, default: false },
    targetId: String,
  },
  emits: [
    'closed',
  ],
})
export default class PlaceCompetitorModal extends Vue {
  filterValue = '';

  targetId!: string;

  get filteredCompetitors() {
    return this.filterValue.trim().length < 1 ? this.competitors.sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1))
      : this.competitors?.filter((c, i, a) => (`${c.name.toLowerCase()} ${c.surname.toLowerCase()}`).indexOf(this.filterValue.toLowerCase()) >= 0).sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1));
  }

  mounted() {
    this.loadCompetitors();
  }

  get competitors(): ICompetitor[] {
    return this.$store.getters.competitors;
  }

  close() {
    this.$emit('closed');
  }

  loadCompetitors(): void {
    this.axios.get(`${process.env.VUE_APP_APIURL}/competitors`)
      .then((res) => {
        console.log(res.data);
        this.$store.dispatch('setCompetitors', res.data);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  update(person: Competitor) {
    // Send request to merge competitors
    console.log(person);
    const requestModel = {
      sourceCompetitorId: person.id,
      targetCompetitorId: this.targetId,
    };
    console.log(requestModel);
    this.axios.post(`${process.env.VUE_APP_APIURL}/competitors/merge`, requestModel)
      .then((res) => {
        this.close();
      });
  }
}
