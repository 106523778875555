<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <label for="filter" class="block text-sm font-medium text-gray-700">Filter</label>
              <div class="mt-1">
                <input type="text" name="filter" id="filter" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="John Smith"
                       v-model="filterValue"
                />
              </div>
            </div>
            <div>
              <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <person-select-list @select="update" :competitors="filteredCompetitors"/>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="close" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import PersonSelectList from '@/components/PersonSelectList.vue';
import Competitor from '@/models/Competitor';
import ICompetitor from '@/models/interfaces/ICompetitor';

@Options({
  components: {
    PersonSelectList,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: { type: Boolean, default: false },
    targetId: String,
  },
  emits: [
    'closed',
  ],
})
export default class PlaceCompetitorModal extends Vue {
  filterValue = '';

  targetId!: string;

  get filteredCompetitors() {
    return this.filterValue.trim().length < 1 ? this.competitors.sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1))
      : this.competitors?.filter((c, i, a) => (`${c.name.toLowerCase()} ${c.surname.toLowerCase()}`).indexOf(this.filterValue.toLowerCase()) >= 0).sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1));
  }

  mounted() {
    this.loadCompetitors();
  }

  get competitors(): ICompetitor[] {
    return this.$store.getters.competitors;
  }

  close() {
    this.$emit('closed');
  }

  loadCompetitors(): void {
    this.axios.get(`${process.env.VUE_APP_APIURL}/competitors`)
      .then((res) => {
        console.log(res.data);
        this.$store.dispatch('setCompetitors', res.data);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  update(person: Competitor) {
    // Send request to merge competitors
    console.log(person);
    const requestModel = {
      sourceCompetitorId: person.id,
      targetCompetitorId: this.targetId,
    };
    console.log(requestModel);
    this.axios.post(`${process.env.VUE_APP_APIURL}/competitors/merge`, requestModel)
      .then((res) => {
        this.close();
      });
  }
}
</script>
