
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';
import PlaceCompetitorModal from '@/components/modals/PlaceCompetitorModal.vue';

@Options({
  components: { PlaceCompetitorModal },
  props: {
    person: Competitor,
    slug: String,
    year: String,
    novice: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
})
export default class BestInShow extends Vue {
  modalOpen = false;

  person!: Competitor;

  placeCompetitor() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
    this.$emit('update');
  }
}
