
import { Options, Vue } from 'vue-class-component';
import ITemplate from '@/models/interfaces/ITemplate';

function dateRange(startDate: Date, endDate: Date): Date[] {
  const start = new Date(startDate);
  const startYear = start.getFullYear();
  const end = new Date(endDate);
  const endYear = end.getFullYear();
  const dates = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : end.getMonth();
    const startMon = i !== startYear ? 0 : start.getMonth();
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      dates.push(new Date(i, j, 1));
    }
  }
  return dates;
}

@Options({
  props: {
    templates: Array,
    year: String,
  },
})
export default class TemplateList extends Vue {
  templates!: ITemplate[];

  viewTemplate(template: ITemplate) {
    this.$router.push({ name: 'Template', params: { templateId: template.id } });
  }
}
