
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';
import PlaceCompetitorModal from '@/components/modals/PlaceCompetitorModal.vue';

@Options({
  components: { PlaceCompetitorModal },
  props: {
    position: Number,
    person: Competitor,
    slug: String,
    year: String,
    categoryId: Number,
  },
  emits: ['update'],
})
export default class Placing extends Vue {
  position!: 1|2|3

  modalOpen = false;

  person!: Competitor;

  get positionText(): string {
    switch (this.position) {
      case 1: return '1st';
      case 2: return '2nd';
      case 3: return '3rd';
      default: return '';
    }
  }

  placeCompetitor() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
    this.$emit('update');
  }
}
