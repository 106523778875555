
import { Options, Vue } from 'vue-class-component';
import {
  getAuth, updateProfile, updateEmail, updatePassword,
} from 'firebase/auth';

@Options({
  components: {},
})
export default class Settings extends Vue {
  editingName = false;

  editingEmail = false;

  editingPassword = false;

  password = '';

  newPassword = '';

  confirmPassword = '';

  get user() {
    return this.$store.getters.user ?? { email: '', displayName: '' };
  }

  editName() {
    this.editingName = true;
  }

  saveName() {
    const auth = getAuth();
    updateProfile(auth.currentUser!, {
      displayName: this.user.displayName,
    }).then(() => {
      // Profile updated!
      this.editingName = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

  editEmail() {
    this.editingEmail = true;
  }

  saveEmail() {
    const auth = getAuth();
    updateEmail(auth.currentUser!, this.user.email).then(() => {
      // Email updated!
      this.editingEmail = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

  editPassword() {
    this.editingPassword = true;
    this.password = '';
  }

  savePassword() {
    const auth = getAuth();
    updatePassword(auth.currentUser!, this.newPassword).then(() => {
      // Email updated!
      this.editingPassword = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }
}
