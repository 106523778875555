
import { Options, Vue } from 'vue-class-component';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import PasswordResetConfirmation from '@/components/Alerts/PasswordResetConfirmation.vue';

@Options({
  components: { PasswordResetConfirmation, ForgotPasswordModal },
})
export default class Login extends Vue {
  email = '';

  password = '';

  forgotPasswordOpen = false;

  passwordResetConfirmed = false;

  forgotPassword() {
    this.forgotPasswordOpen = true;
  }

  forgotPasswordConfirm() {
    this.passwordResetConfirmed = true;
    this.forgotPasswordOpen = false;
  }

  login() {
    console.log('Logging in');
    const auth = getAuth();
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {
        const { user } = userCredential;
        this.$store.dispatch('login', user);
        this.$router.push({ name: 'Home' });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }
}
