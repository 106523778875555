<template>
  <nav class="h-full overflow-y-auto" aria-label="Directory">
    <ul class="relative z-0 divide-y divide-gray-200">
      <li v-for="(template, idx) in templates" :key="idx" class="bg-white" @click="viewTemplate(template)">
        <div class="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <div class="flex-1 min-w-0">
            <a href="#" class="focus:outline-none">
              <!-- Extend touch target to entire panel -->
              <span class="absolute inset-0" aria-hidden="true" />
              <p class="text-sm font-medium text-gray-900">
                {{ template.name }}
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ template.location }}
              </p>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ITemplate from '@/models/interfaces/ITemplate';

function dateRange(startDate: Date, endDate: Date): Date[] {
  const start = new Date(startDate);
  const startYear = start.getFullYear();
  const end = new Date(endDate);
  const endYear = end.getFullYear();
  const dates = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : end.getMonth();
    const startMon = i !== startYear ? 0 : start.getMonth();
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      dates.push(new Date(i, j, 1));
    }
  }
  return dates;
}

@Options({
  props: {
    templates: Array,
    year: String,
  },
})
export default class TemplateList extends Vue {
  templates!: ITemplate[];

  viewTemplate(template: ITemplate) {
    this.$router.push({ name: 'Template', params: { templateId: template.id } });
  }
}
</script>
