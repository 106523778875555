
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String,
      location: String,
    },
    templateId: String,
  },
  emits: [
    'closed',
  ],
})
export default class EditTemplateModal extends Vue {
  editingItem!: { name: string, location: string };

  templateId!: string;

  name = '';

  location = '';

  created() {
    this.name = this.editingItem.name;
    this.location = this.editingItem.location;
  }

  close() {
    this.$emit('closed');
  }

  update() {
    // Send request to update show
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/templates`,
      {
        name: this.name,
        id: this.templateId,
        location: this.location,
      },
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }
}
