
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';

@Options({
  components: {
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String,
      location: String,
    },
  },
  emits: [
    'closed',
  ],
})
export default class AddEditTemplateSlideOver extends Vue {
  open!: boolean;

  editingItem!: { name: string, location: string };

  selectedName = '';

  close(refresh = false): void {
    this.$emit('closed', refresh);
  }

  create(): void {
    this.axios.put(`${process.env.VUE_APP_APIURL}/templates`, this.editingItem)
      .then(() => this.close(true));
  }
}
