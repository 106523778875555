
import { Options, Vue } from 'vue-class-component';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Home extends Vue {
  navigation = [
    {
      name: 'Home', href: '#', icon: HomeIcon, current: true,
    },
    {
      name: 'History', href: '#', icon: ClockIcon, current: false,
    },
    {
      name: 'Balances', href: '#', icon: ScaleIcon, current: false,
    },
    {
      name: 'Cards', href: '#', icon: CreditCardIcon, current: false,
    },
    {
      name: 'Recipients', href: '#', icon: UserGroupIcon, current: false,
    },
    {
      name: 'Reports', href: '#', icon: DocumentReportIcon, current: false,
    },
  ];

  secondaryNavigation = [
    { name: 'Settings', href: '#', icon: CogIcon },
    { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
    { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
  ]

  cards = [
  ]

  transactions = [
    {
      id: 1,
      name: 'Payment to Molly Sanders',
      href: '#',
      amount: '$20,000',
      currency: 'USD',
      status: 'success',
      date: 'July 11, 2020',
      datetime: '2020-07-11',
    },
    // More transactions...
  ]

  statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.cards = res.data.map((d: { id: number, name: string }) => ({
        name: d.name,
        href: '#',
      })));
  }

  // eslint-disable-next-line class-methods-use-this
  get salutation(): string {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return 'Good morning';
    } if (curHr < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }

  sidebarOpen = false;

  get userName() {
    const { user } = this.$store.getters;
    console.log(user);
    return user ? user.displayName : 'User';
  }
}
