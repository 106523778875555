
import { Options, Vue } from 'vue-class-component';
import { CheckCircleIcon } from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';

@Options({
  components: {
    CheckCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
  },
  emits: [
    'close',
  ],
})
export default class PasswordResetConfirmation extends Vue {
  close() {
    this.$emit('close');
  }
}
