
import { Options, Vue } from 'vue-class-component';
import { getAuth, signOut } from 'firebase/auth';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  CogIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  TemplateIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  CalendarIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    TemplateIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Main extends Vue {
  navigation = [
    {
      name: 'Home', href: '#', icon: HomeIcon, current: true, to: { name: 'Home' },
    },
    {
      name: 'Years', href: '#', icon: CalendarIcon, current: false, to: { name: 'Years' },
    },
    {
      name: 'Competitors', href: '#', icon: UserGroupIcon, current: false, to: { name: 'Competitors' },
    },
    {
      name: 'Results', href: '#', icon: DocumentReportIcon, current: false, to: { name: 'Results' },
    },
    {
      name: 'Templates', href: '#', icon: TemplateIcon, current: false, to: { name: 'Templates' },
    },
  ];

  secondaryNavigation = [
    {
      name: 'Settings', href: '#', icon: CogIcon, to: { name: 'Settings' },
    },
  ]

  statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
  }

  sidebarOpen = false;

  get user() {
    return this.$store.getters.user;
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/explicit-module-boundary-types
  logout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      this.$router.push({ name: 'Login' });
    }).catch((error) => {
      // An error happened.
    });
  }
}
