<template>
  <div class="sm:px-5">
      <div>
        <label for="search" class="block text-sm font-medium text-gray-700">Search</label>
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <UsersIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input type="text" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="John Smith" v-model="filterValue" />
          </div>
        </div>
      </div>
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5"
      v-for="(competitor, idx) in filteredCompetitors"
      :key="idx"
    >

      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-2">
            <span v-if="competitor.novice" class="inline-flex items-center px-3 py-0.5 my-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
              Novice{{ competitor.noviceYear ? ` ${competitor.noviceYear}` : null}}
            </span>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ competitor.name }} {{ competitor.surname }}
            </h3>
          </div>
          <div class="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="edit(competitor)"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ICompetitor from '@/models/interfaces/ICompetitor';
import { UsersIcon } from '@heroicons/vue/solid';

@Options({
  props: {
    competitors: Array,
  },
  emits: ['editing'],
  components: {
    UsersIcon,
  },
})
export default class CompetitorList extends Vue {
  // eslint-disable-next-line class-methods-use-this
  competitors!: ICompetitor[];

  filterValue = '';

  get filteredCompetitors(): ICompetitor[] {
    return this.filterValue.trim().length < 1 ? this.competitors.sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1))
      : this.competitors?.filter((c, i, a) => (`${c.name.toLowerCase()} ${c.surname.toLowerCase()}`).indexOf(this.filterValue.toLowerCase()) >= 0).sort((a, b) => (a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1));
  }

  edit(competitor: ICompetitor) {
    this.$emit('editing', competitor);
  }
}
</script>
