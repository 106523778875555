<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Competitors
              </h2>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="addCompetitorClicked">
              Add New Person
            </button>
          </div>
        </div>
      </div>
    </div>
    <competitor-list v-if="competitors" :competitors="competitors" @editing="editItem"/>
    <add-edit-competitor-slide-over :open="addEditOpen" @closed="closeSlideover" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import CompetitorList from '@/components/CompetitorList.vue';
import AddEditCompetitorSlideOver from '@/components/SlideOvers/AddEditCompetitorSlideOver.vue';
import ICompetitor from '@/models/interfaces/ICompetitor';

@Options({
  components: {
    AddEditCompetitorSlideOver,
    CompetitorList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Competitors extends Vue {
  addEditOpen = false;

  competitors: ICompetitor[] | null = null;

  created() {
    this.loadCompetitors();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  addCompetitorClicked(): void {
    this.$store.dispatch('addCompetitor');
    this.addEditOpen = true;
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    this.loadCompetitors();
  }

  loadCompetitors(): void {
    this.axios.get(`${process.env.VUE_APP_APIURL}/competitors`)
      .then((res) => {
        this.competitors = res.data;
      });
  }

  editItem(editCompetitor: ICompetitor) {
    this.$store.dispatch('editCompetitor', editCompetitor);
    this.addEditOpen = true;
  }
}
</script>
