import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './firebase';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';

createApp(App).use(store).use(router).use(VueAxios, axios)
  .mount('#app');

axios.interceptors.request.use(async (config) => {
  const userObj = store.state.user;
  if (userObj) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const token = await userObj.accessToken;
    // eslint-disable-next-line no-param-reassign
    config!.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
});
