
import { Options, Vue } from 'vue-class-component';
import Category from '@/models/Category';
import Placing from '@/components/Placing.vue';
import ShowJudgeBestInShow from '@/components/ShowJudgeBestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { ShowJudgeBestInShow, Placing },
  props: {
    categories: Array(Category),
    slug: String,
    year: String,
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
  },
  emits: ['editing', 'update'],
})
export default class ShowCategoryList extends Vue {
  categories!: Category[];

  bestInShow!: Competitor;

  noviceBestInShow!: Competitor;

  slug!: string;

  year!: string;

  edit(category: Category) {
    this.$emit('editing', category);
  }

  update() {
    this.$emit('update');
  }
}
