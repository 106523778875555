<template>
  <div v-if="currentShow">
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {{ currentShow.name }} - {{ year }}
              </h2>
            </div>
            <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ showDate }}
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <LocationMarkerIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ currentShow.location }}
              </div>
              <div v-if="currentShow.judge" class="mt-2 flex items-center text-sm text-gray-500">
                <ScaleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ currentShow.judge }}
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              @click="deleteShow"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Delete
            </button>
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="editDetails"
            >
              <PencilIcon class="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              Edit
            </button>
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="addCategoryClicked">
              Add Show Category
            </button>
          </div>
        </div>
      </div>
    </div>
    <show-category-list :categories="currentShow.categories" :best-in-show="currentShow.bestInShow" :novice-best-in-show="currentShow.noviceBestInShow" @editing="editItem" @update="loadShows" :slug="currentShow.slug" :year="year"/>

    <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4" v-if="currentShow.categories.length > 0">
      <button type="button"
              class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="addCategoryClicked">
        Add Show Category
      </button>
    </div>
    <add-edit-category-slide-over :open="addEditOpen" :editingItem="editingItem" :slug="slug" :year="year" @closed="closeSlideover" />
    <edit-show-modal :open="editModalOpen" :editing-item="currentShow" :slug="slug" @closed="closeEditModal" :year="year"/>
    <are-you-sure-modal @confirm="confirmDelete" @closed="areYouSureDeleteOpen = false" :open="areYouSureDeleteOpen" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
  PencilIcon,
  CalendarIcon,
  ScaleIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditCategorySlideOver from '@/components/SlideOvers/AddEditCategorySlideOver.vue';
import IShow from '@/models/interfaces/IShow';
import ShowCategoryList from '@/components/ShowCategoryList.vue';
import EditShowModal from '@/components/modals/EditShowModal.vue';
import ICategory from '@/models/interfaces/ICategory';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    EditShowModal,
    ShowCategoryList,
    AddEditCategorySlideOver,
    YearShowList,
    ScaleIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    LocationMarkerIcon,
    PencilIcon,
    CalendarIcon,
  },
})
export default class Show extends Vue {
  addEditOpen = false;

  editModalOpen = false;

  editingItem: ICategory | null = null;

  currentShow: IShow | null = null;

  areYouSureDeleteOpen = false;

  created(): void {
    this.loadShows();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  get slug(): string {
    return Array.isArray(this.$route.params.slug) ? this.$route.params.slug[0] : this.$route.params.slug;
  }

  get showDate(): string {
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    };
    return this.currentShow ? new Date(this.currentShow.date).toLocaleDateString('en-GB', options) : '';
  }

  // eslint-disable-next-line class-methods-use-this
  addCategoryClicked(): void {
    this.$store.dispatch('addCategory');
    this.addEditOpen = true;
  }

  editDetails() {
    this.editModalOpen = true;
  }

  editItem(editCategory: ICategory) {
    this.$store.dispatch('editCategory', editCategory);
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  closeEditModal(refresh: boolean): void {
    this.editModalOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadShows(): void {
    console.log('Loading shows...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`)
      .then((res) => {
        console.log(res);
        this.currentShow = res.data;
      });
  }

  deleteShow(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('deleting');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`)
      .then((res) => {
        this.$router.push({ name: 'Year', params: { year: this.year } });
      });
  }
}
</script>
