
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';

@Options({
  props: {
    competitors: Array(Competitor),
  },
  emits: ['select'],
})
export default class PersonSelectList extends Vue {
  competitors!: Competitor[]

  select(person: Competitor) {
    this.$emit('select', person);
  }
}
