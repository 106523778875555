<template>
  <main class="flex-1">
    <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="pt-10 pb-16">
          <div class="px-4 sm:px-6 md:px-0">
            <div class="mt-10 divide-y divide-gray-200">
              <div class="space-y-1">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Profile
                </h3>
                <p class="max-w-2xl text-sm text-gray-500">
                  This information will be displayed publicly so be careful what you share.
                </p>
              </div>
              <div class="mt-6">
                <dl class="divide-y divide-gray-200">
                  <div v-if="editingName" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                      <div class="flex-grow">
                        <input type="text" name="name" id="name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="user.displayName" />
                      </div>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      @click="saveName"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Save
                              </button>
                            </span>
                    </dd>
                  </div>
                  <div v-else class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="flex-grow">{{ user.displayName }}</span>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      @click="editName"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Update
                              </button>
                            </span>
                    </dd>
                  </div>
                  <div v-if="editingEmail" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt class="text-sm font-medium text-gray-500">
                      Email
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="flex-grow">
                        <input type="email" name="email" id="email" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="user.email" />
                      </div>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      @click="saveEmail"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Save
                              </button>
                            </span>
                    </dd>
                  </div>
                  <div v-else class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt class="text-sm font-medium text-gray-500">
                      Email
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="flex-grow">{{ user.email }}</span>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      @click="editEmail"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Update
                              </button>
                            </span>
                    </dd>
                  </div>
                  <div v-if="editingPassword" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt class="text-sm font-medium text-gray-500">
                      Current Password
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="flex-grow">
                        <input type="password" name="password" id="password" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="password" />
                      </div>
                    </dd>
                    <dt class="text-sm font-medium text-gray-500">
                      New Password
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="flex-grow">
                        <input type="password" name="new-password" id="new-password" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="newPassword" />
                      </div>
                    </dd>
                    <dt class="text-sm font-medium text-gray-500">
                      Confirm New Password
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="flex-grow">
                        <input type="password" name="confirm-password" id="confirm-password" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="confirmPassword" />
                      </div>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      v-if="newPassword.length > 0 && newPassword === confirmPassword"
                                      @click="savePassword"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Save
                              </button>
                            </span>
                    </dd>
                  </div>
                  <div v-else class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt class="text-sm font-medium text-gray-500">
                      Password
                    </dt>
                    <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="flex-grow">*************</span>
                      <span class="ml-4 flex-shrink-0">
                              <button type="button"
                                      @click="editPassword"
                                      class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Update
                              </button>
                            </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  getAuth, updateProfile, updateEmail, updatePassword,
} from 'firebase/auth';

@Options({
  components: {},
})
export default class Settings extends Vue {
  editingName = false;

  editingEmail = false;

  editingPassword = false;

  password = '';

  newPassword = '';

  confirmPassword = '';

  get user() {
    return this.$store.getters.user ?? { email: '', displayName: '' };
  }

  editName() {
    this.editingName = true;
  }

  saveName() {
    const auth = getAuth();
    updateProfile(auth.currentUser!, {
      displayName: this.user.displayName,
    }).then(() => {
      // Profile updated!
      this.editingName = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

  editEmail() {
    this.editingEmail = true;
  }

  saveEmail() {
    const auth = getAuth();
    updateEmail(auth.currentUser!, this.user.email).then(() => {
      // Email updated!
      this.editingEmail = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

  editPassword() {
    this.editingPassword = true;
    this.password = '';
  }

  savePassword() {
    const auth = getAuth();
    updatePassword(auth.currentUser!, this.newPassword).then(() => {
      // Email updated!
      this.editingPassword = false;
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }
}
</script>
