<template>
  <div class="sm:px-5">
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5"
    >
      <div class="px-4 py-5 sm:p-6">
        <dl class="mt-5 grid grid-cols-1 gap-5">
          <best-in-show @update="update" :person="bestInShow" v-bind="$props"/>
          <best-in-show @update="update" novice :person="noviceBestInShow" v-bind="$props"/>
        </dl>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Placing from '@/components/Placing.vue';
import BestInShow from '@/components/BestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { BestInShow, Placing },
  props: {
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
    slug: String,
    year: String,
  },
  emits: ['editing', 'update'],
})
export default class ShowJudgeBestInShow extends Vue {
  bestInShow!: Competitor;

  noviceBestInShow!: Competitor;

  update() {
    this.$emit('update');
  }
}
</script>
