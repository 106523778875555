<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow py-8 px-8">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 lg:border-t lg:border-gray-200">
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Years
              </h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <button type="button"
                      class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="addNewClicked">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-lg leading-6 font-medium text-gray-900">Years</h2>
        <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <!-- Card -->
          <div v-for="card in cards" :key="card.name"
               class="bg-white overflow-hidden shadow rounded-lg">
            <div class="p-5">
              <div class="flex items-center">
                <div class="w-0 flex-1">
                  <dl>
                    <dd>
                      <div class="text-xl font-medium text-gray-900">
                        {{ card.name }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-5 py-3">
              <div class="text-sm">
                <router-link :to="{ name: 'Year', params: { year: card.name }}" tag="a"
                             class="font-medium text-cyan-700 hover:text-cyan-900">
                  View shows
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEditYearSlideOver :open="addEditOpen" :editingItem="editingItem" @closed="closeSlideover"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt1Icon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';
import AddEditYearSlideOver from '@/components/SlideOvers/AddEditYearSlideOver.vue';

@Options({
  components: {
    AddEditYearSlideOver,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Years extends Vue {
  addEditOpen = false;

  editingItem: { name: string, id?: number | undefined } | undefined;

  cards = [
    // More items...
  ]

  statusStyles = {
    published: 'bg-green-100 text-green-800',
    draft: 'bg-yellow-100 text-yellow-800',
    empty: 'bg-gray-100 text-gray-800',
  }

  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.refreshData();
    }
  }

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/Years`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.cards = res.data.map((d: { id: number, name: string }) => ({
        name: d.name,
        href: '#',
      })));
  }

  addNewClicked(): void {
    const nextYear = new Date().getFullYear().toString();
    this.editingItem = { name: nextYear };
    this.addEditOpen = true;
  }
}
</script>
