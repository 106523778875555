<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="z-10 fixed inset-0 overflow-hidden" @close="close" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-2xl">
              <form class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-6 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1" v-if="isEditing">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Edit person details
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Amends details about a person
                        </p>
                      </div>
                      <div class="space-y-1" v-else>
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Create new person
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Creates a new person
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                @click="close(false)">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                    <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                      <div>
                        <label for="name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          First Name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="text" name="name" id="name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItemName" />
                      </div>

                      <div>
                        <label for="surname" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Surname
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="text" name="surname" id="surname" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItemSurname" />
                      </div>

                      <div>
                        <label for="novice-year" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Year Started as Novice
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="number" name="novice-year" id="novice-year" min="0" max="2050" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItemNoviceYear" />
                      </div>

                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            id="novice"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            v-model="editingItemNovice"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="novice" class="font-medium text-gray-700">Novice</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 flex justify-between">
                  <div v-if="isEditing" class="space-x-3 flex justify-start">
                    <button type="button" @click="modalOpen = true" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Merge
                    </button>
                  </div>
                  <div class="space-x-3 flex justify-end">
                    <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            @click="close(false)">
                      Cancel
                    </button>
                    <button type="button" @click="create" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{ isEditing ? 'Update' : 'Create' }}
                    </button>
                    <button v-if="isEditing" type="button" @click="deactivate" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      Deactivate
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
      <merge-competitor-modal :target-id="editingCompetitorId" :open="modalOpen" @closed="closeModal" />
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import MergeCompetitorModal from '@/components/modals/MergeCompetitorModal.vue';

@Options({
  components: {
    MergeCompetitorModal,
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
  },
  emits: [
    'closed',
  ],
})
export default class AddEditCompetitorSlideOver extends Vue {
  open!: boolean;

  modalOpen = false;

  closeModal(): void {
    this.modalOpen = false;
  }

  get isEditing(): boolean {
    console.log(this.$store.state.editingCompetitor);
    return !!this.$store.state.editingCompetitor.id;
  }

  get editingCompetitorId(): string {
    return this.$store.state.editingCompetitor.id;
  }

  get editingItemName(): string {
    return this.$store.state.editingCompetitor.name;
  }

  set editingItemName(val: string) {
    this.$store.dispatch('setEditCompetitorName', val);
  }

  get editingItemSurname(): string {
    return this.$store.state.editingCompetitor.surname;
  }

  set editingItemSurname(val: string) {
    this.$store.dispatch('setEditCompetitorSurname', val);
  }

  get editingItemNovice(): boolean {
    return this.$store.state.editingCompetitor.novice;
  }

  set editingItemNovice(val: boolean) {
    this.$store.dispatch('setEditCompetitorNovice', val);
  }

  get editingItemNoviceYear(): boolean {
    return this.$store.state.editingCompetitor.noviceYear;
  }

  set editingItemNoviceYear(val: boolean) {
    this.$store.dispatch('setEditCompetitorNoviceYear', val);
  }

  close(refresh = false): void {
    this.$emit('closed', refresh);
  }

  create(): void {
    if (!this.isEditing) {
      this.axios.put(`${process.env.VUE_APP_APIURL}/competitors`, this.$store.state.editingCompetitor)
        .then(() => {
          this.close(true);
        });
    } else {
      this.axios.post(`${process.env.VUE_APP_APIURL}/competitors`, this.$store.state.editingCompetitor)
        .then(() => this.close(true));
    }
  }

  merge(): void {
    if (this.isEditing) {
      console.log('Merging');
    }
  }

  deactivate(): void {
    this.axios.delete(`${process.env.VUE_APP_APIURL}/competitors/${this.$store.state.editingCompetitor.id}`)
      .then(() => this.close(true));
  }
}
</script>
