<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Year {{ year }}
              </h2>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              @click="deleteYear"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Delete
            </button>
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="editShowClicked">
              Edit
            </button>
            <button type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="addShowClicked">
              Add Show
            </button>
          </div>
        </div>
      </div>
    </div>
    <year-show-list v-if="shows" :shows="shows" :year="year"/>
    <add-edit-show-slide-over :open="addEditOpen" :editingItem="editingItem" @closed="closeSlideover" />
    <edit-year-modal :open="editYearOpen" @closed="closeEditYear" :editing-item="yearObject"/>
    <are-you-sure-modal @confirm="confirmDelete" @closed="areYouSureDeleteOpen = false" :open="areYouSureDeleteOpen" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditShowSlideOver from '@/components/SlideOvers/AddEditShowSlideOver.vue';
import IShow from '@/models/interfaces/IShow';
import EditYearModal from '@/components/modals/EditYearModal.vue';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    EditYearModal,
    AddEditShowSlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
  },
})
export default class Year extends Vue {
  addEditOpen = false;

  editYearOpen = false;

  editingItem: { name: string, year: string, location: string, date: Date } | null = null;

  calendar: { name: string, number: number, shows: { name: string, year: string, location: string, date: Date }[] }[] | null = null;

  yearObject: { id: string, name: string, published: boolean, shows: IShow[] } | null = null;

  shows: IShow[] | null = null;

  areYouSureDeleteOpen = false;

  created() {
    this.loadShows();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  // eslint-disable-next-line class-methods-use-this
  addShowClicked(): void {
    this.editingItem = {
      name: '', year: this.year, location: '', date: new Date(parseInt(this.year, 10), 0, 1),
    };
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  editShowClicked(): void {
    this.editYearOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  closeEditYear(refresh: boolean): void {
    this.editYearOpen = false;
    if (refresh) {
      this.loadShows();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadShows(): void {
    console.log('Loading shows...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/years/${this.year}`)
      .then((res) => {
        this.yearObject = res.data;
        this.shows = res.data.shows;
      });
  }

  deleteYear(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('deleting');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/years/${this.yearObject?.id}`)
      .then((res) => {
        this.$router.push({ name: 'Years' });
      });
  }
}
</script>
