
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
  PencilIcon,
  CalendarIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditCategorySlideOver from '@/components/SlideOvers/AddEditCategorySlideOver.vue';
import ITemplate from '@/models/interfaces/ITemplate';
import ShowCategoryList from '@/components/ShowCategoryList.vue';
import EditShowModal from '@/components/modals/EditShowModal.vue';
import ICategoryTemplate from '@/models/interfaces/ICategoryTemplate';
import EditTemplateModal from '@/components/modals/EditTemplateModal.vue';
import AddEditCategoryTemplateSlideOver
  from '@/components/SlideOvers/AddEditCategoryTemplateSlideOver.vue';
import TemplateCategoryList from '@/components/TemplateCategoryList.vue';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    TemplateCategoryList,
    AddEditCategoryTemplateSlideOver,
    EditTemplateModal,
    EditShowModal,
    ShowCategoryList,
    AddEditCategorySlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    LocationMarkerIcon,
    PencilIcon,
    CalendarIcon,
  },
})
export default class Template extends Vue {
  addEditOpen = false;

  editModalOpen = false;

  editingItem: ICategoryTemplate | null = null;

  currentTemplate: ITemplate | null = null;

  areYouSureDeleteOpen = false;

  created(): void {
    this.loadTemplates();
  }

  get templateId(): string {
    return Array.isArray(this.$route.params.templateId) ? this.$route.params.templateId[0] : this.$route.params.templateId;
  }

  // eslint-disable-next-line class-methods-use-this
  addCategoryClicked(): void {
    this.$store.dispatch('addCategoryTemplate');
    this.addEditOpen = true;
  }

  editDetails(): void {
    this.editModalOpen = true;
  }

  editItem(editCategory: ICategoryTemplate): void {
    this.$store.dispatch('editCategoryTemplate', editCategory);
    this.addEditOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  closeSlideover(refresh: boolean): void {
    this.addEditOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  deleteTemplate(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('deleting');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/templates/${this.templateId}`)
      .then(() => {
        this.areYouSureDeleteOpen = false;
        this.$router.push({ name: 'Templates' });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  closeEditModal(refresh: boolean): void {
    this.editModalOpen = false;
    if (refresh) {
      this.loadTemplates();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadTemplates(): void {
    console.log('Loading template...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/templates/${this.templateId}`)
      .then((res) => {
        console.log(res);
        this.currentTemplate = res.data;
      });
  }
}
