<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Name
                    </label>
                  </div>
                  <div class="relative sm:col-span-2">
                    <input type="text" name="name" id="name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" :class="yearErrorClass" v-model="editingItem.name" :aria-invalid="yearError" aria-describedby="year-error"  />
                    <div v-if="yearError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                  </div>
                  <p v-if="yearError" class="mt-2 text-sm text-red-600 sm:col-span-3" id="year-error">{{yearError}}</p>
                  <SwitchGroup as="div" class="flex items-center justify-between sm:col-span-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <span class="flex-grow flex flex-col">
                      <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Published</SwitchLabel>
                      <SwitchDescription as="span" class="text-sm text-gray-500">{{ isPublished ? 'Results will be available to view' : 'Results will not be available to view' }}</SwitchDescription>
                    </span>
                    <Switch v-model="isPublished" :class="[isPublished ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                      <span :class="[isPublished ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                        <span :class="[isPublished ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                          <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span>
                        <span :class="[isPublished ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                          <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                  </SwitchGroup>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="update">
                Save
              </button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="close" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchDescription, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationCircleIcon,
    SelectMenu,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    open: Boolean,
    editingItem: {
      id: String,
      name: String,
      published: Boolean,
    },
  },
  emits: [
    'closed',
  ],
})
export default class EditYearModal extends Vue {
  editingItem!: { id: string, name: string, published: boolean };

  slug!: string;

  year!: string;

  location = '';

  yearError: string | null = null;

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  get yearErrorClass() {
    return this.yearError ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : '';
  }

  get name(): string {
    return this.editingItem?.name;
  }

  set name(val: string) {
    this.editingItem.name = val;
  }

  get isPublished(): boolean {
    return this.editingItem?.published;
  }

  set isPublished(val: boolean) {
    this.editingItem.published = val;
  }

  created() {
    console.log(this.editingItem);
  }

  close() {
    this.yearError = null;
    this.$emit('closed');
  }

  update() {
    // Send request to update show
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years`, this.editingItem,
    ).then((res) => {
      // Then close & reload
      this.$router.push({ name: 'Year', params: { year: this.editingItem.name } });
      this.$emit('closed', true);
      this.yearError = null;
    }).catch((err) => {
      if (err.request) {
        this.yearError = err.request.response;
      }
    });
  }
}
</script>
