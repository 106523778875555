<template>
  <div class="sm:px-5">
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5"
      v-for="(item, idx) in categories"
      :key="idx"
    >

      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{item.name}} - {{ item.description }}
              <span v-if="item.novice" class="inline-flex items-center px-3 py-0.5 my-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                Novice
              </span>
            </h3>
          </div>
          <div class="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="edit(item)"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import CategoryTemplate from '@/models/CategoryTemplate';

@Options({
  props: {
    categories: Array(CategoryTemplate),
    templateId: String,
  },
  emits: ['editing', 'update'],
})
export default class TemplateCategoryList extends Vue {
  categories!: CategoryTemplate[];

  templateId!: string;

  edit(category: CategoryTemplate) {
    this.$emit('editing', category);
  }

  update() {
    this.$emit('update');
  }
}
</script>
