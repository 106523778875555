<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form action="#" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700 text-left">
                  Email address
                </label>
                <div class="mt-1">
                  <input id="email" v-model="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700 text-left">
                  Password
                </label>
                <div class="mt-1">
                  <input id="password" v-model="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div class="text-sm">
                  <a @click.prevent="forgotPassword" class="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  @click.prevent="login"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" src="../assets/ChatsworthSticks.jpg" alt="Walking sticks" />
    </div>
    <forgot-password-modal @closed="forgotPasswordOpen = false" :open="forgotPasswordOpen" @confirm="forgotPasswordConfirm"/>
    <password-reset-confirmation :open="passwordResetConfirmed" @close="passwordResetConfirmed = false"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import PasswordResetConfirmation from '@/components/Alerts/PasswordResetConfirmation.vue';

@Options({
  components: { PasswordResetConfirmation, ForgotPasswordModal },
})
export default class Login extends Vue {
  email = '';

  password = '';

  forgotPasswordOpen = false;

  passwordResetConfirmed = false;

  forgotPassword() {
    this.forgotPasswordOpen = true;
  }

  forgotPasswordConfirm() {
    this.passwordResetConfirmed = true;
    this.forgotPasswordOpen = false;
  }

  login() {
    console.log('Logging in');
    const auth = getAuth();
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {
        const { user } = userCredential;
        this.$store.dispatch('login', user);
        this.$router.push({ name: 'Home' });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }
}
</script>
